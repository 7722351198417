

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <vs-spacer />

        <current-branch style="width:150px;" class="" />

        <!-- <search-bar /> -->

        <!-- <cart-drop-down />

        <notification-drop-down />

        <profile-drop-down />-->
        <div @click="$router.push('/backoffice/web-submit')">
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer mt-1 sm:mr-6 mr-2"
            :badge="$store.state.notinumber"
          ></feather-icon>
        </div>
        <div class="the-navbar__user-meta flex items-center">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ user_displayName }}</p>
            <small>({{user_displayRole}})</small>
          </div>
          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
             <div class="con-img ml-3">
              <img
                key="onlineImg"
                :src="activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
            <!-- <div class="con-img ml-3">
              <img
                v-if="activeUserImg.startsWith('http')"
                key="onlineImg"
                :src="activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
              <img
                v-else
                key="localImg"
                :src="require(`@/assets/images/portrait/small/${activeUserImg}`)"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              /> -->
            </div>
            <vs-dropdown-menu>
              <ul style="min-width: 9rem">
                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/pages/profile')"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Profile</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/email')"
                >
                  <feather-icon icon="MailIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Inbox</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/todo')"
                >
                  <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Tasks</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/chat')"
                >
                  <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Chat</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/eCommerce/wish-list')"
                >
                  <feather-icon icon="HeartIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Wish List</span>
                </li>-->
                <!-- <vs-divider class="m-1"></vs-divider> -->
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/backoffice/me')"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Profile</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Logout</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import DropdownDefault from "../../../views/components/vuesax/dropdown/DropdownDefault";
import Bookmarks from "./components/Bookmarks.vue";
import SearchBar from "./components/SearchBar.vue";
import CartDropDown from "./components/CartDropDown.vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import CurrentBranch from './components/CurrentBranch.vue';

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    }
  },
  data() {
    return {
       uImage: null
    }
  },
  components: {
    DropdownDefault,
    Bookmarks,
    // I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    CurrentBranch
  },
  created() {
    
    // console.log('this.$store.state.auth.roles[0].roleName >>>>>>>>> ',this.$store.state.auth.roles[0].roleName);

     console.log('this.$store.getters.auth.getRole >>>>>>>>> ',this.$store.getters["auth/getRole"] );

  },
  computed: {
    userImage: {
      get(){
        // console.log('computed ******** NAVBAR USER GET IMAGE',this.$store.getters["auth/currentUserImage"]);

        return this.$store.getters["auth/currentUserImage"];
      },
      set(){}
    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark")
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
    // PROFILE
    user_displayName() {
      // return JSON.parse(localStorage.getItem('userInfo')).displayName
      return JSON.parse(localStorage.getItem("username")) || "Guest";
    },
    // ROLEs
    user_displayRole() {
      // return JSON.parse(localStorage.getItem('userInfo')).displayName
      return this.$store.getters["auth/getRole"];
    },
    activeUserImg() {
      // return JSON.parse(localStorage.getItem('userInfo')).photoURL || this.$store.state.AppActiveUser.img;
      // var image = JSON.parse(localStorage.getItem("userImage"));
      var image  = this.userImage;
      if (image === null || image === undefined) image = "defaultimage.png";

      return this.$store.state.userImageStore + image;
    }
  },
  watch:{
    // userImage: function(){
    //   console.log('******** NAVBAR USER WATCH IMAGE');
    //   this.activeUserImg();

    // }
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$store.dispatch("auth/userLogout");
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    }
  }
};
</script>

<style>
ul.vs-component.vs-dropdown--menu {
  width: 150px !important;
}
</style>
