<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template functional>

  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <!-- TUTORIAL POP UP -->
    <template>
      <!-- TUTORIAL  POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="TUTORIAL"
        :active.sync="tutorialPopupActive"
      >
        <div style="width:80%; margin: 5px auto;">
          <div class="vx-col w-full mb-base">
              <!-- <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/watch?v=UFuTAbXLHuQ&amp;feature=youtu.be" allowfullscreen/> -->
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UFuTAbXLHuQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <span>
      COPYRIGHT &copy; {{ new Date().getFullYear() }}
      <a
        href="https://alexrental.app"
        target="_blank"
        rel="nofollow"
      >Alex Rental App</a>, All rights Reserved
    </span>

    <!-- <span class="md:flex hidden items-center">
      <span>Hand-crafted & Made with</span>
      <feather-icon
        icon="HeartIcon"
        svgClasses="stroke-current text-danger w-6 h-6"
        class="ml-2"
      />
    </span>-->

    <!-- buyNow component -->
    <!-- <component :is="injections.components.BuyNow"></component> -->
  </footer>
</template>

<script>
import BuyNow from "../../components/BuyNow.vue";

export default {
  name: "the-footer",
  // data() {
  //   return {
  //     tutorialPopupActive : false
  //   }
  // },
  // methods: {
  //   openopop(){
  //     this.tutorialPopupActive = true;
  //   }
  // },
  props: {
    classes: {
      type: String
    }
  },
  inject: {
    components: {
      default: {
        BuyNow
      }
    }
  }
};
</script>
