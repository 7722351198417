<template>
<div class="border-animation" >
  <vs-dropdown  vs-custom-content vs-trigger-click class="cursor-pointer">
    <span class="cursor-pointer flex items-center i18n-locale"  >
      <!-- <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" /> -->
      <span class=" ml-2 pr-2 pt-2 text-in-border-animation"  >{{ branchNameSub() }}</span>
      <feather-icon  icon="ChevronDownIcon"   class="pt-2 icon-in-animation" />
    </span>
    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
       <vs-dropdown-item v-for="(item, index) in getMyBranches" :key="index" @click="updateBranch(item)" > &nbsp;{{item.branchName}}</vs-dropdown-item>
      <!-- <vs-dropdown-item @click="updateLocale('en')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" /> &nbsp;สาขา 1</vs-dropdown-item>
      <vs-dropdown-item @click="updateLocale('fr')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/fr.png" alt="fr" /> &nbsp;French</vs-dropdown-item>
      <vs-dropdown-item @click="updateLocale('de')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/de.png" alt="de" /> &nbsp;German</vs-dropdown-item>
      <vs-dropdown-item @click="updateLocale('pt')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/pt.png" alt="pt" /> &nbsp;Portuguese</vs-dropdown-item> -->
    </vs-dropdown-menu>
  </vs-dropdown>
</div>
</template>

<script>
export default {
  data() {
    return {
      branchName: "",
    }
  },
  created() {
    this.branchName = this.getCurrentBranch.branchName;
  },
  computed: {

    getMyBranches() {
      return this.$store.getters["auth/myBranches"];
    },
    getCurrentBranch() {
      return this.$store.getters["auth/currentBranch"];
    }
  },

  methods: {
    branchNameSub(){
      return this.branchName.substr(0,12);
    },
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },
    async updateBranch(item) {
      this.branchName = item.branchName;

      await this.$store.dispatch("auth/changeBranch", item.branchId);
    },
  }
}
</script>


<style scoped >

@media only screen and (max-width: 578px) {
  .icon-in-animation{
    display: none !important;
  }
}

.icon-in-animation{
  width: 15px !important;
}
.text-in-border-animation{
  font-size:1.0rem; font-family: mitr;
}
.items-center{
  font-size: 0.9rem !important;
}

.border-animation {
  margin: 0 !important;
  width: 110px;
  height: 40px;
  margin-right:20px !important;
  /* font-size: 1rem !important; */
  text-align: center;
  font-family: mitr;
  background-color: white;
  color: #999999;
  border: 2px solid #9189f3;
  box-shadow: 1px 1px px #9189f3;

  border-radius: 25px;
  /* margin: 5vw 0 0 43vw; */
/*   background-image:linear-gradient(to bottom left, #0cf, #3f3f3f); */
  animation-name: animation;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
}



@keyframes animation {
  0% { border-color: #9189f3;box-shadow: 1px 1px 15px #9189f3;}
  25% {border-color: #8d85f380;;box-shadow: -1px -1px 20px #8d85f380;}
  50% {border-color: #e6e4fd;box-shadow: 0px 0px 15px#e6e4fd;  }
  75% {border-color: #8d85f380;; box-shadow: -1px -1px 15px #8d85f380;}
  100% {border-color: #9189f3;box-shadow: 1px 1px 15px #9189f3;}
}

.vs-con-dropdown{
  font-size: 0.3em;
}
</style>
