<template>
  <div class="parentx">
    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isSidebarActive"
      default-index="-1"
      position-right
    >
      <!-- <vee-validate> -->
        <div
          class="header-sidebar flex items-end justify-between"
          slot="header"
        >
          <h2>เพิ่มสินค้า</h2>
        </div>

        <div class="grid grid-cols-4 mt-8 mb-12">
          <div class="col-span-4 flex flex-col items-center justify-center">
            <div
              v-if="previewImage.length === 0"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              class="upload_image"
              @click="$refs.file.click()"
            />
            <img
              v-if="previewImage"
              @click="$refs.file.click()"
              :src="previewImage"
              alt="preview"
              style="width: 150px; height: 150px; object-fit: contain"
            />
            <input
              type="file"
              ref="file"
              style="display:none"
              name="image"
              v-on:change="onChangeImage()"
            />
            <span class="text-danger">
              {{ errors.first("image") }}
            </span>
          </div>
        </div>

        <div class="grid grid-cols-4 px-6">
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center justify-end pr-4">
                ชื่อสินค้า<span class="pl-2">:</span>
              </div>
              <div class="col-span-3">
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  name="product"
                  v-model="productInfo.name"
                />
                <span class="text-danger">
                  {{ errors.first("product") }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center justify-end pr-4">
                ราคา<span class="pl-2">:</span>
              </div>
              <div class="col-span-3">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="price"
                  v-model="productInfo.price"
                />
                <span class="text-danger">
                  {{ errors.first("price") }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center justify-end pr-4">
                จำนวน<span class="pl-2">:</span>
              </div>
              <div class="col-span-3">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="stock"
                  v-model="productInfo.inStock"
                />
                <span class="text-danger">
                  {{ errors.first("stock") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end space-x-4 pr-5">
          <vs-button color="primary" @click="submitSaleProduct">ตกลง</vs-button>
          <vs-button type="border" color="danger" @click="closeSidebarRight"
            >ยกเลิก</vs-button
          >
        </div>
      <!-- </vee-validate> -->
    </vs-sidebar>
  </div>
</template>

<script>
import axios from "axios";
import { Validator } from 'vee-validate';
export default {
  data() {
    return {
      productInfo: {
        name: "New Item",
        inStock: 0,
        price: 0
      },
      previewImage: "",
      formDataSaleProduct: new FormData()
    };
  },
  components:{
    Validator
  },
  computed: {
    isSidebarActive: {
      get() {
        return this.$store.state.sidebar.sidebarRightCreateProduct;
      },
      set(val) {
        return val;
      }
    }
  },
  methods: {
    closeSidebarRight() {
      this.$store.dispatch("sidebar/sidebarRightCreateProductToggle", false);
      this.clearFields();
    },
    addVoucher() {
      this.closeSidebarRight();
      this.$store.dispatch("sidebar/sidebarRightCreateVoucherToggle", true);
    },
    onChangeImage() {
      console.log(`>>> change image`);
      const file = this.$refs.file.files[0];
      this.previewImage = URL.createObjectURL(file);

      this.formDataSaleProduct.append("File", file, file.name);
    },
    clearFields() {
      this.previewImage = "";
      this.productInfo = {
        name: "New Item",
        inStock: 0,
        price: 0
      };
    },


    async uploadSaleProductImage(data) {
      const url = `${this.$store.state.apiURL}/api/v2/PostSaleProductImage`;
      const axiosHeader = {
        headers: {
          Authorization: this.$store.state.auth.apiHeader.headers.Authorization,
          "Content-Type": "multipart/form-data"
        }
      };

      this.formDataSaleProduct.append("saleProductId", data.saleProductId);

      await axios({
        method: "post",
        url: url,
        data: this.formDataSaleProduct,
        ...axiosHeader
      });


      // let data = res.data;
      // console.log(`>>> uploadSaleProductImage`, data);
    },
    submitSaleProduct() {
      // validate
      let res = null;
      this.$validator.validate().then(async valid => {
        if (valid) {
          const url = `${this.$store.state.apiURL}/api/v2/PostSaleProduct`;

              res = await axios.post(url, this.productInfo, this.$store.state.auth.apiHeader);


                const data = res.data;
                console.log(data);

              // upload image after create sale-product
              await this.uploadSaleProductImage(data);

              this.$root.$emit("triggerFetchProduct", true);
              this.closeSidebarRight();

              this.$store.commit("productList/SET_CHANGE");

            window.location.href = "/backoffice/sale-order"
        }
      });
    }
  }
};
</script>

<style>
.vs-sidebar-position-right {
  max-width: 400px !important;
}

.border-error input {
  border-color: #f44336 !important;
}
</style>
