

export default [
  //  ALEX MENU

  {
    header: "แดชบอร์ด",
    icon: "PackageIcon",
    i18n: "แดชบอร์ด",
    items: [

      {
        url: "/backoffice/statistics",
        name: "Chart",
        slug: "StatisticChart",
        icon: "show_chart",
        tagColor: "success",
        i18n: "ข้อมูลสถิติ"
      },
      {
        url: "/backoffice/calendar",
        name: "Calendar",
        slug: "calendar-simple-calendar",
        icon: "calendar_today",
        tagColor: "success",
        i18n: "ปฏิทิน"
      },
      {
        url: "/backoffice/todo",
        name: "Todo",
        slug: "todo",
        icon: "check_box",
        i18n: "รายการที่ต้องทำ",
        disabled: true
      },
      {
        url: "/backoffice/web-submit",
        name: "eCommerce",
        slug: "eCommerce",
        icon: "shopping_basket",
        i18n: "ออเดอร์หน้าเว็บ"
      }
      // {
      //   url: null,
      //   name: "User",
      //   icon: "UserIcon",
      //   i18n: "User",
      //   submenu: [{
      //       url: '/apps/user/user-list',
      //       name: "List",
      //       slug: "app-user-list",
      //       i18n: "List",
      //     },
      //     {
      //       url: '/apps/user/user-view/268',
      //       name: "View",
      //       slug: "app-user-view",
      //       i18n: "View",
      //     },
      //     {
      //       url: '/apps/user/user-edit/268',
      //       name: "Edit",
      //       slug: "app-user-edit",
      //       i18n: "Edit",
      //     },
      //   ]
      // },
    ]
  },

  {
    header: "จัดการหน้าร้าน",
    icon: "PackageIcon",
    i18n: "จัดการหน้าร้าน",
    items: [
      // {
      //   url: null,
      //   name: 'กระบวนการจัดชุด',
      //   icon: "MenuIcon",
      //   i18n: 'กระบวนการจัดชุด',
      //   submenu: [
      //     {
      //       url: "/backoffice/pickup",
      //       name: 'pickup',
      //       slug: "pickup",
      //       i18n: 'รอรับชุด'
      //     },
      //     {
      //       url: "/backoffice/return",
      //       name: 'return',
      //       slug: "return",
      //       i18n: 'รอคืนชุด'
      //     },
      //     {
      //       url: "/backoffice/alreadyreturn",
      //       name: 'alreadyreturn',
      //        slug: "alreadyreturn",
      //       i18n: 'คืนชุดแล้ว'
      //     },

      //   ]
      // },


      {
        url: "/backoffice/rental",
        name: "ใบจอง(บาร์โค้ด)",
        slug: "rental",
        icon: "shopping_cart",
        tagColor: "success",
        i18n: "ใบจอง(บาร์โค้ด)"
      },{
        url: "/backoffice/online-billing",
        name: "ใบจอง(แคตตาล็อก)",
        slug: "rental",
        icon: "touch_app",
        tagColor: "success",
        i18n: "ใบจอง(แคตตาล็อก)"
      },
      {
        url: "/backoffice/pickup",
        name: "pickup",
        slug: "pickup",
        icon: "watch_later",
        i18n: "รายการรอรับชุด"
      },

      {
        url: "/backoffice/return",
        name: "return",
        slug: "return",
        icon: "settings_backup_restore",
        i18n: "รายการรอคืนชุด",
      },

      {
        url: "/backoffice/alreadyreturn",
        name: "alreadyreturn",
        slug: "alreadyreturn",
        icon: "done_outline",
        i18n: "รายการคืนชุดแล้ว"
      },

      {
        url: "/backoffice/saved",
        name: "Saved",
        slug: "Saved",
        icon: "save",
        i18n: "รายการบันทึกการเช่า"
      }
    ]
  },

   {
    header: "สินค้าสำหรับขาย",
    icon: "PackageIcon",
    i18n: "สินค้าสำหรับขาย",
    items: [

      {
        url: "/backoffice/sale-order",
        name: "sale-order",
        slug: "sale_order",
        icon: "local_mall",
        tagColor: "success",
        i18n: "ออกบิลขาย"
      },
      {
        url: "/backoffice/list-sale-order",
        name: "list-sale-order",
        slug: "list_sale_order",
        icon: "list_alt",
        i18n: "รายการขาย"
      },

    ]
  },

   {
    header: "สินค้ารอดำเนินการ",
    icon: "PackageIcon",
    i18n: "สินค้ารอดำเนินการ",
    items: [

      {
        url: "/backoffice/cleanandfix-items",
        name: "สินค้ารอดำเนินการ",
        slug: "cleanandfix_items",
        icon: "opacity",
        tagColor: "success",
        i18n: "สินค้ารอดำเนินการ"
      },
      {
        url: "/backoffice/cleanandfix-order",
        name: "cleanandfix-order",
        slug: "cleanandfix-order",
        icon: "description",
        i18n: "ออเดอร์กำลังดำเนินการ"
      },

      {
        url: "/backoffice/cleanandfix-shop",
        name: "cleanandfix-shop",
        slug: "cleanandfix-shop",
        icon: "local_laundry_service",
        i18n: "ผู้ติดต่อ",
      },


    ]
  },


  {
    header: "สต็อคสินค้า",
    icon: "PackageIcon",
    i18n: "สต็อคสินค้า",
    items: [{
        url: "/backoffice/stock",
        name: "สต็อคสินค้า",
        slug: "rental",
        icon: "archive",
        tagColor: "success",
        i18n: "สต็อคสินค้า"
      },
      // {
      //   url: "/backoffice/borrow",
      //   name: "borrow",
      //   slug: "borrow",
      //   icon: "repeat",
      //   i18n: "รายการขอยืมชุด"
      // }
    ]
  },

  {
    header: "บัญชีการเงิน",
    // icon: "PackageIcon",
    i18n: "บัญชีการเงิน",
    items: [{
        url: "/backoffice/accounting",
        name: "accounting",
        slug: "accounting",
        icon: "account_balance_wallet",
        tagColor: "success",
        i18n: "บันทึกค่าใช้จ่าย"
      },
      {
        url: "/backoffice/moneyback",
        name: "moneyback",
        slug: "moneyback",
        icon: "monetization_on",
        i18n: "บัญชีรายรับ"
      }
    ]
  },

  {
    header: "ข้อมูลผู้ใช้ระบบ",
    // icon: "PackageIcon",
    i18n: "ข้อมูลผู้ใช้ระบบ",
    items: [{
        url: "/backoffice/shop-info",
        name: "shopinfo",
        slug: "shopinfo",
        icon: "store_mall_directory",
        tagColor: "success",
        i18n: "ข้อมูลหน้าร้าน"
      },
      {
        url: "/backoffice/user-management",
        name: "usermanagement",
        slug: "usermanagement",
        icon: "supervisor_account",
        i18n: "บัญชีผู้ใช้งาน"
      },
      {
        url: "/backoffice/customer",
        name: "customerinfo",
        slug: "customerinfo",
        icon: "emoji_people",
        i18n: "ข้อมูลลูกค้า"
      }
    ]
  },

  {
    header: "ส่วนจัดการสำหรับแอดมิน",
    // icon: "PackageIcon",
    i18n: "ส่วนจัดการสำหรับแอดมิน",
    items: [



      {
        url: "/backoffice/config/category",
        name: "category",
        slug: "category",
        icon: "category",
        i18n: "ประเภทสินค้า"
      },
      {
        url: "/backoffice/config/texture",
        name: "texture",
        slug: "texture",
        icon: "touch_app",
        i18n: "ชนิด"
      },
      {
        url: "/backoffice/config/size",
        name: "size",
        slug: "size",
        icon: "format_size",
        i18n: "ขนาด"
      },
      {
        url: "/backoffice/config/color",
        name: "color",
        slug: "color",
        icon: "color_lens",
        i18n: "รหัสสี"
      },

      {
        url: "/backoffice/config/web-category",
        name: "webcategory",
        slug: "webcategory",
        icon: "web",
        i18n: "หมวดหมู่หน้าเว็บ"
      },
      {
        url: "/backoffice/config/monetary",
        name: "monetary",
        slug: "monetary",
        icon: "attach_money",
        i18n: "หมวดหมู่ค่าใช้จ่าย"
      },

      {
        url: null,
        name: "",
        slug: "",
        icon: "",
        i18n: ""
      },
      {
        url: null,
        name: "",
        slug: "",
        icon: "",
        i18n: ""
      }
    ]
  }

  //  ALEX MENU
];
