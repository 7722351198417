<template>
  <div class="parentx">
    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isSidebarActive"
      default-index="-1"
      position-right
    >
      <div>
        <div
          class="header-sidebar flex items-end justify-between"
          slot="header"
        >
          <h2>สร้าง Voucher</h2>
        </div>

        <div class="grid grid-cols-4 mt-8 mb-12">
          <div class="col-span-4 flex flex-col items-center justify-center">
            <div
              v-if="previewImage.length === 0"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              class="upload_image"
              @click="$refs.file.click()"
            />
            <img
              v-if="previewImage"
              @click="$refs.file.click()"
              :src="previewImage"
              alt="preview"
              style="width: 150px; height: 150px; object-fit: contain"
            />
            <input
              type="file"
              ref="file"
              style="display:none"
              name="image"
              v-on:change="onChangeImage()"
            />
            <span class="text-danger">
              {{ errors.first("image") }}
            </span>
          </div>
        </div>

        <div class="grid grid-cols-4 px-6">
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center  pr-4">
                ชื่อสินค้า<span class="pl-2">:</span>
              </div>
              <div class="col-span-3">
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  name="product"
                  v-model="productInfo.name"
                />
              </div>
              <div class="col-span-4 flex flex-col items-end justify-center">
                <span class="text-danger">
                  {{ errors.first("product") }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center  pr-4">
                ราคา<span class="pl-2">:</span>
              </div>
              <div class="col-span-1">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="price"
                  v-model="productInfo.price"
                />
              </div>
              <div class="col-span-1 flex items-center  pr-4">
                เติมเงิน<span class="pl-2">:</span>
              </div>
              <div class="col-span-1">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="value"
                  v-model="productInfo.value"
                />
              </div>
              <div class="col-span-4 flex flex-col items-end justify-center">
                <span class="text-danger">
                  {{ errors.first("price") }}
                </span>
                <span class="text-danger">
                  {{ errors.first("value") }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center  pr-4">
                จำนวน<span class="pl-2">:</span>
              </div>
              <div class="col-span-3">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="stock"
                  v-model="productInfo.inStock"
                />
                <span class="text-danger">
                  {{ errors.first("stock") }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-4 mb-6">
            <div class="grid grid-cols-4">
              <div class="col-span-1 flex items-center  pr-4">
                อายุ<span class="pl-2">:</span>
              </div>
              <div class="col-span-1">
                <vs-input
                  class="w-full"
                  v-validate="'required|min_value:1'"
                  name="month"
                  v-model="productInfo.month"
                />
              </div>
              <div class="col-span-2 flex items-center pl-4">
                เดือน
              </div>
              <div class="col-span-4 flex flex-col items-end justify-center">
                <span class="text-danger">
                  {{ errors.first("month") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex  space-x-4 pr-5 justify-end">
          <vs-button color="primary" @click="submitVoucher">ตกลง</vs-button>
          <vs-button type="border" color="danger" @click="closeSidebarRight"
            >ยกเลิก</vs-button
          >
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      productInfo: {
        name: "New Item",
        price: 0,
        inStock: 0,
        month: 0,
        value: 0
      },
      previewImage: "",
      formData: new FormData()
    };
  },
  computed: {
    ...mapGetters({
      isSidebarActive: "sidebarRightCreteVoucher"
    }),
    isSidebarActive: {
      get() {
        return this.$store.state.sidebar.sidebarRightCreateVoucher;
      },
      set(val) {
        return val;
      }
    }
  },
  methods: {
    closeSidebarRight() {
      this.$store.dispatch("sidebar/sidebarRightCreateVoucherToggle", false);
      this.clearFields();
    },
    addVoucher() {
      this.closeSidebarRight();
      this.$store.dispatch("sidebar/sidebarRightCreateVoucherToggle", true);
    },
    onChangeImage() {
      console.log(`>>> change image`);
      const file = this.$refs.file.files[0];
      this.previewImage = URL.createObjectURL(file);

      this.formData.append("File", file, file.name);
    },
    clearFields() {
      this.previewImage = "";
      this.productInfo = {
        name: "New Item",
        price: 0,
        inStock: 0,
        month: 0,
        value: 0
      };
    },
    uploadVoucherImage(data) {
      const url = `${this.$store.state.apiURL}/api/v2/PostVoucherImage`;
      const axiosHeader = {
        headers: {
          Authorization: this.$store.state.auth.apiHeader.headers.Authorization,
          "Content-Type": "multipart/form-data"
        }
      };

      this.formData.append("voucherId", data.voucherId);

      axios({
        method: "post",
        url: url,
        data: this.formData,
        ...axiosHeader
      }).then(res => {
        const data = res.data;
        console.log(`>>> uploadVoucherImage`, data);
      });
    },
    submitVoucher() {
      // validate

      this.$validator.validate().then(valid => {
        if (valid) {
          const url = `${this.$store.state.apiURL}/api/v2/PostVoucher`;

          axios
            .post(url, this.productInfo, this.$store.state.auth.apiHeader)
            .then(res => {
              const data = res.data;
              console.log(data);

              // upload image after create sale-product
              this.uploadVoucherImage(data);

              this.$root.$emit("triggerFetchVoucher", true);
              this.closeSidebarRight();
              this.$store.commit("productList/SET_CHANGE");
            });
        }
      });
    }
  }
};
</script>

<style>
.vs-sidebar-position-right {
  max-width: 400px !important;
}

.border-error input {
  border-color: #f44336 !important;
}
</style>
